<template>
  <svg
    version="1.1"
    id="Laag_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 18"
    style="enable-background: new 0 0 18 18"
    xml:space="preserve"
    width="18"
    height="18"
    title="Meer info"
  >
    <path
      class="st0"
      d="M9,0C4.03,0,0,4.03,0,9s4.03,9,9,9s9-4.03,9-9S13.97,0,9,0z M7.61,3.73C7.68,3.55,7.79,3.4,7.93,3.26
	c0.14-0.13,0.3-0.24,0.48-0.32c0.18-0.08,0.37-0.12,0.58-0.12c0.21,0,0.41,0.04,0.59,0.12c0.18,0.08,0.34,0.19,0.48,0.32
	s0.24,0.29,0.32,0.47c0.08,0.18,0.12,0.37,0.12,0.58c0,0.21-0.04,0.41-0.12,0.59c-0.08,0.19-0.19,0.35-0.32,0.49
	S9.76,5.64,9.58,5.72C9.4,5.8,9.2,5.84,8.99,5.84c-0.21,0-0.4-0.04-0.58-0.12C8.23,5.64,8.07,5.53,7.93,5.39
	C7.79,5.25,7.68,5.09,7.61,4.9C7.53,4.72,7.48,4.52,7.48,4.31C7.48,4.11,7.53,3.91,7.61,3.73z M11.63,14.32H6.37V13.1
	c0.85,0,1.27-0.22,1.27-0.67V8.61c0-0.46-0.42-0.68-1.27-0.68V6.73h4v5.7c-0.02,0.45,0.4,0.67,1.27,0.67V14.32z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
svg {
  cursor: pointer;
}
.st0 {
  fill: #33aaff;
}
</style>
